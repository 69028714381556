@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AirbnbCerealBd";   /*Can be any text*/
  src: local("AirbnbCerealBd"),
    url("./fonts/AirbnbCereal_W_Bd.otf");
  font-weight: bold;
}

@font-face {
  font-family: "AirbnbCerealBk";   /*Can be any text*/
  src: local("AirbnbCerealBk"),
    url("./fonts/AirbnbCereal_W_Bk.otf");
}

@font-face {
  font-family: "AirbnbCerealBlk";   /*Can be any text*/
  src: local("AirbnbCerealBlk"),
    url("./fonts/AirbnbCereal_W_Blk.otf");
}

@font-face {
  font-family: "AirbnbCerealLight";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/AirbnbCereal_W_Lt.otf") format("OpenType");
}

@font-face {
  font-family: "AirbnbCerealMd";   /*Can be any text*/
  src: local("AirbnbCerealMd"),
    url("./fonts/AirbnbCereal_W_Md.otf");
}

@font-face {
  font-family: "AirbnbCerealXBd";   /*Can be any text*/
  src: local("AirbnbCerealXBd"),
    url("./fonts/AirbnbCereal_W_XBd.otf");
}

body {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}