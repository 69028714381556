.place-detail-flex {
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.title-place {
	font-size: 1.5rem;
}

.details-container {
	display: flex;
	padding: 1rem 0rem;
}
.details {
display: flex;
}

.detail-rating {
	display: flex;
}

.separador {
	padding: 0rem 1rem;
}

.text-evaluation {
	text-decoration: underline;
}

.detail-superhost {
	display: flex;
}

.text-content {
	padding: 0rem 0.5rem;
}

.actions-items {
	display: flex;
	justify-content: flex-end;
	padding: 0rem 8rem;
}

.action-share {
	display: flex;
	padding: 0rem 1rem;
}

.action-save {
	display: flex;
}

.text-underline {
 text-decoration: underline;
}

.places-img {
	display: grid;
	grid-template-columns: auto auto auto auto;
	margin:  0rem;
	padding: 1rem;
}

.contain-img {
	padding: 0rem 0.3rem;
}

.content-img-1 {
	grid-column:1 / 3 ;
	grid-row: 1 / 3;
} 

