#background {
  @apply z-20 top-0 left-0 w-full;
  position: fixed;
  font-family: AirbnbCerealBd;
  height: 100%;
  background-color: rgba(255,255,255,0.5); /* Replace with your desired background color */
}

.newplace {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  contain: strict;
  box-shadow: 0 0 20px 6px;
}

.input-text {
  @apply bg-gray-700 border border-gray-600 text-white text-sm rounded-lg  block w-full p-2.5;
}

#defaultModalButton {
  @apply block hover:bg-gray-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center border border-black;
}
