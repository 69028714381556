.filter-nav {
  font-family: AirbnbCerealLight;
  border-right: 2px solid lightgray;
}

.filter-nav::-webkit-scrollbar {
  display: none;
}

.filter-opt {
  @apply flex flex-wrap justify-center items-center mx-2;
  cursor: pointer;
}

.filter-icon {
  @apply h-8 w-8 text-gray-500  transform hover:text-black;
}

.filter {
  @apply mx-4 my-3 text-xs leading-5 text-gray-500 transition-colors duration-300 transform dark:text-gray-500 hover:text-black dark:hover:text-black hover:no-underline md:my-0;
}

.button-filter-container{
  margin-right: 2rem;
}

.button-filter{
  @apply py-2 px-4 flex justify-center items-center  bg-white hover:bg-gray-100 focus:ring-red-500 focus:ring-offset-red-200 text-black w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg;
  border: 1px solid gray;
  font-family: AirbnbCerealMd;
  font-size: 0.7rem;
}

.button-filter-icon {
  @apply h-4 w-4;
  margin-right: 0.5rem;
}
