footer {
  font-family: AirbnbCerealLight;
}

.footer-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-left: -68px;
  z-index: 1;
  background-color: white;
}

.row {
  justify-content: space-between;
  border-top: 1px solid #000000;
  border-color: rgb(221, 221, 221);
}

.col-md-6 {
  display: inline-block; 
  vertical-align: middle; 
  width: 50%;
}

.data-f-1 {
  justify-content: flex-end; 
  padding-left: -10%;
  font-size: .89em;
  word-spacing: 0.2em;
}

.data-f-2 {
  justify-content: flex-end; 
  padding-top: 10px;
  padding-right: 8%;
  padding-left: 20px;
  padding-bottom: 1%;
  word-spacing: 0.2em;
  font-size: .88em;
  font-weight: 100;
  vertical-align: middle;
}

.margin-a {
  margin-right: -1%;
}

.margin-b {
  margin-left: 2%;
  margin-right: 2%;
}

.margin-c {
  margin-left: 2%;
}

.up-icon {
  margin-left: 1%;
}